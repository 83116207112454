import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Details.css';

const Details = () => {
    return (
        <>
            <section className="provider-details-sec">
                <Container>
                    <Row>
                        <Col md={7} lg={8}>
                            <div className="single-provider-details">
                                <h2>Tresa Parker <span>(ACPNP, RN)</span></h2>
                                <p>Meet Tresa, a caring board certified Acute Care Pediatric Nurse Practitioner who's here to make healthcare easy for your family. After graduating from Duke University in 2020, she's dedicated herself to helping children feel better, right in the comfort of their homes. With years of experience caring for sick kids in hospitals across the country, she's passionate about keeping up with the latest in pediatric health.</p>
                                <p>She understands the challenges parents face when their little ones get sick, especially outside of regular doctor hours. That's why she started Peony Health - to offer reliable healthcare that fits your schedule and budget, whether it's day or night. In addition to urgent care needs, If your child has been previously diagnosed with ADHD, Tresa offers medication management for ADHD. If you're concerned about your child's symptoms and have not been previously diagnosed she can guide you on where to get a proper evaluation. </p>
                                <p>As a mom herself, she knows how important it is to have someone you can trust when your child isn't feeling well. She's excited to be there for your family, providing caring and accessible healthcare whenever you need it.</p>
                            </div>
                            <div className="provider-award">
                                <h3>Credentials</h3>
                                <ul className="p-0">
                                    <li>Acute Care Pediatric Nurse Practitioner</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={5} lg={4}>
                            <div className="provider-profile text-center">
                                <div className="profile-img"></div>
                                <p>Name: <strong>Tresa Parker</strong></p>
                                <p>Specialization: <strong>Pediatrics</strong></p>
                                {/* <p>Phone: <strong>1-866-764-5387</strong></p> */}
                                <div className="doctors-social">
                                    <a href=".#"><FontAwesomeIcon icon={faFacebook} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faTwitter} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faLinkedin} /></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="provider-details-sec">
                <Container>
                    <Row>
                        <Col md={7} lg={8}>
                            <div className="single-provider-details">
                                <h2>Vera Mano<span>(FNP, RN)</span></h2>
                                <p>Meet Vera Mano, a board-certified Family Nurse Practitioner with a passion for delivering comprehensive healthcare across all stages of life. She obtained her degree from the prestigious University of North Carolina at Chapel Hill in 2020, and since then, she has been dedicated to providing accessible and reliable healthcare services to families in need..</p>
                                <p>At Peony Health, her mission is to bridge the gap between patients and quality healthcare by offering convenient telemedicine consultations. Vera is passionate about providing top-notch care to people of all ages, right  from the comfort of your own home. At Peony Health, Vera believes that healthcare should be accessible to everyone, regardless of their location or circumstances, and is committed to making that a reality. Whether it’s a simple check-up or managing more complex health issues, Vera is dedicated to being there for you and your family every step of the way </p>
                                <p>One area of specialization that she’s particularly passionate about is weight loss management, utilizing a comprehensive approach encompassing pharmacotherapy, tailored exercise regimens, and personalized nutrition plans to facilitate successful outcomes. She understands that achieving and maintaining a healthy weight can be a challenging journey, and she is here to  provide personalized support and guidance to help you every step of the way to reach your health goals. Whether you're looking to shed a few pounds or embark on a transformative weight loss journey, she will work closely with you to develop a personalized plan tailored to your unique needs and goals.</p>
                                <p>Because Vera believes that every family deserves to be healthy and happy, they're committed to making sure that getting the care you need is as easy as possible. She also believes that optimal health is achievable for everyone, and she is dedicated to helping you reach your full potential. Thank you for considering Peony Health Telemedicine as your partner in health. Vera cannot wait to help you and your family live your best lives.I look forward to supporting you on your path to a healthier, happier life.</p>
                            </div>
                            <div className="provider-award">
                                <h3>Credentials</h3>
                                <ul className="p-0">
                                    <li>Family Nurse Practitioner</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={5} lg={4}>
                            <div className="provider-profile text-center">
                                <div className="profile-img2"></div>
                                <p>Name: <strong>Vera Mano</strong></p>
                                <p>Specialization: <strong>Adults</strong></p>
                                {/* <p>Phone: <strong>1-866-764-5387</strong></p> */}
                                <div className="doctors-social">
                                    <a href=".#"><FontAwesomeIcon icon={faFacebook} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faTwitter} /></a>
                                    <a href=".#"><FontAwesomeIcon icon={faLinkedin} /></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <section className="pb-5">
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="achivement-img-bg"></div>
                    </Col>
                    <Col lg={6}>
                        <div className="expertProvider-txt mt-5 mt-lg-0">
                            <h2>Experienced Provider</h2>
                            <p>Smiling comes naturally to Dr. Harrie, author of ‘Donto’. He has embraced Cosmetic Providerry and has redesigned the smiles for thev thousands of patients.</p>
                            <p>Dr. Harrie believes in providing her patients with more than just world class dental care. He also helps patients recognize the vital connection between dental health and whole body health. A graduate of the University of California’s School of Providerry, Dr. Harrie is a leader in the movement to bring environmental sanity and well-being into the dental world for future.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section> */}
        </>
    );
};

export default Details;