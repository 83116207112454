export const FakeService = [
    {
        "id": "001",
        "title": "Pediatric Metabolic Health",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=24714&format=png"
    },
    {
        "id": "002",
        "title": "Adult Metabolic Health",
        "description": "For individuals, over the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=lXcsKgjNtoiK&format=png"
    },
    {
        "id": "003",
        "title": "Allergies",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=4lCV8ixvkJ0p&format=png"
    },

    {
        "id": "004",
        "title": "Colds & Fevers",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=ksoeWrgutNct&format=png"
    },

    {
        "id": "005",
        "title": "Cuts & Scrapes",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=8fBwjfy9jT6b&format=png"
    },

    {
        "id": "006",
        "title": "Ear Infections",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=9558&format=png"
    },
    {
        "id": "007",
        "title": "Eye Irritations",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=B2AL26R2LYT-&format=png"
    },
    {
        "id": "008",
        "title": "Rashes",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=jsG9p3iJAUM6&format=png"
    },
    {
        "id": "009",
        "title": "Sleep Issues",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=10770&format=png"
    },
    {
        "id": "010",
        "title": "Stings & Bites",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=SOWTSpS5W0Hp&format=png"
    },
    {
        "id": "011",
        "title": "Stomach & GI Issues",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=5353&format=png"
    }

]