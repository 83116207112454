import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import expertProvider from '../../../Images/veraAndTresa.jpg';
import './Provider.css';

const Provider = () => {
    return (
        <section className='expert-provider'>
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <img src={expertProvider} alt="expertProvider" className="img-fluid" />
                    </Col>
                    <Col lg={6}>
                        <div className="expertProvider-txt mt-5 mt-lg-0">
                            <h2>Experienced Providers</h2>
                            <p>Proving the highest quality of service.</p>
                            <p>Peony Health is here to help.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Provider;