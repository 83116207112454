import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FakeDoctors } from '../../FakeData/Provider';
import Doctors from '../../Pages/Home/Doctors/Doctors';

const Provider = () => {
    return (
        <section className="doctor-wrapper">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="section-title">
                            <h1 className="mb-5 mb-sm-dent">Our Providers</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        FakeDoctors.map(provider => (
                            <Doctors key={provider.id} provider={provider} />
                        ))
                    }
                </Row>
            </Container>
        </section>
    );
};

export default Provider;