export const FakeFeatures = [
    {
        "id": "001",
        "title": "Family Plan",
        "description": "$150/mo",
        "img": "https://img.icons8.com/?size=80&id=Tqqn1DieaMiE&format=png"
    },
    {
        "id": "002",
        "title": "Weight Loss Management",
        "description": "$250",
        "img": "https://img.icons8.com/?size=80&id=107633&format=png"
    },
    {
        "id": "003",
        "title": "One Time Sick Visit",
        "description": "$100",
        "img": "https://img.icons8.com/?size=80&id=QR4wpnuz7t7O&format=png"
    },
    {
        "id": "004",
        "title": "ADHD Management",
        "description": "$150",
        "img": "https://img.icons8.com/?size=80&id=M609HUYcqql8&format=png"
    }
]


